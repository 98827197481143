<template>
	<div class="d-flex flex-column flex-root" style="overflow: hidden;">
		<div
			class="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
			:style="{
				backgroundImage: `url(${backgroundImage})`
			}"
		>
			<!-- begin:: Content -->
			<div class="d-flex flex-column flex-row-fluid text-center">
				<h1
					class="text-white mb-12 display-1"
					style="margin-top: 12rem;"
				>
					<div v-if="status == 1">
						<v-progress-circular
							:size="50"
							indeterminate
						></v-progress-circular>
						Please wait ...
					</div>
					<div v-if="status == 2">Done</div>
					<div v-if="status == 3">Oops!</div>
				</h1>
				<div class="display-4 font-weight-bold text-white">
					<div v-if="status == 1">
						we are working on it
					</div>
					<div v-if="status == 2">
						{{ message }}
						<br />
						<img
							style="margin-top: 3rem;"
							:src="doneImage"
							width="25%"
						/>
					</div>
					<div v-if="status == 3">
						{{ message }}
						<br />
						<img
							style="margin-top: 3rem;"
							:src="wrongImage"
							width="25%"
						/>
					</div>
				</div>
			</div>
			<!-- end:: Content -->
		</div>
	</div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
</style>

<script>
import ApiService from "@/core/services/api.service";

export default {
	name: "verify-mail",
	data() {
		return {
			type: "",
			email: "",
			user: "",
			code: "",
			signature: "",
			status: 1, // 1 = wait, 2 = done, 3 = wrong
			message: ""
		};
	},
	mounted() {
		this.code = this.$route.query.code || "";
		this.email = this.$route.query.email || "";
		this.signature = this.$route.query.signature || "";
		this.type = this.$route.query.type || "";
		this.user = this.$route.query.user || "";
		this.checkMail();
	},
	computed: {
		backgroundImage() {
			return process.env.BASE_URL + "media/bg/bg-1.jpg";
		},
		doneImage() {
			return process.env.BASE_URL + "media/placeholders/done.svg";
		},
		wrongImage() {
			return process.env.BASE_URL + "media/placeholders/wrong.svg";
		}
	},
	methods: {
		checkMail() {
			ApiService.post(
				`client/verify-mail/${this.type}/${this.email}/${this.user}/${this.code}?signature=${this.signature}`
			)
				.then(({ data }) => {
					this.status = 2;
					this.message = data.message;
				})
				.catch(({ response }) => {
					this.status = 3;
					this.message = response.data.message;
				});
		}
	}
};
</script>
